<script setup lang="ts">
import useTime from '@/composables/useTime';

type Props = {
  value: number;
};

const { value } = defineProps<Props>();

const { convertMinutesToTime } = useTime();
</script>

<template>
  <strong class="text-5" v-text="convertMinutesToTime(value)" />
</template>
