import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import api from '@/services/api';
import { IProjectPreviewResource, ITimeEntryProjectResource } from '@/types/Project';
import { IUserListResource, IUserPreviewResource, IUserWorkingDays, UserStatusType } from '@/types/User';
import { IActivityTemplateListResource, IActivityTemplateMinimizeResource } from '@/types/Activity';
import { ITimePeriodAvailableDates } from '@/types/TimePeriod';
import { ISplitEventResource } from '@/types/Event';
import { ProjectPriceType } from '@/types/Common';

export default function useTimeEntry() {
  const { t } = useI18n({ useScope: 'global' });

  // Work duration
  const workingDaysLoading = ref(false);
  const workingDays = ref<null | IUserWorkingDays>(null);

  async function getUserWorkingDays(userUuid: string, weeks: number[]) {
    try {
      workingDaysLoading.value = true;
      const searchParams = new URLSearchParams();
      weeks.forEach((week) => {
        searchParams.append('weeks[]', week.toString());
      });
      workingDays.value = await api.users.workingDays(userUuid, { searchParams });
    } catch (error) {
      console.error(error);
    } finally {
      workingDaysLoading.value = false;
    }
  }

  // Clients
  const clientUuid = ref<null | string>(null);

  // Projects
  const project = reactive<{
    only_invoice_part_of_year: boolean;
    annual_price: null | number;
    discount: null | number;
    estimating_price: null | number;
    price_type: null | ProjectPriceType;
    user: null | IUserPreviewResource;
    is_sub_label_enabled: boolean;
    start_date: string;
    end_date: string;
  }>({
    only_invoice_part_of_year: false,
    annual_price: null,
    discount: null,
    estimating_price: null,
    price_type: null,
    user: null,
    is_sub_label_enabled: false,
    start_date: '',
    end_date: '',
  });

  const projectId = ref<null | number>(null);
  const projects = ref<(IProjectPreviewResource | ITimeEntryProjectResource)[]>([]);
  const projectsLoading = ref(false);
  const projectLoading = ref(false);

  async function getProjects(options?: { team_member?: string }) {
    if (clientUuid.value === null) return;
    try {
      projectsLoading.value = true;
      const searchParams = new URLSearchParams();
      searchParams.append('without_pagination', '1');
      searchParams.append('statuses[]', 'active');
      if (options?.team_member) {
        searchParams.append('team_member', options.team_member);
      }
      const response = await api.projects.index(clientUuid.value, searchParams);
      projects.value = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      projectsLoading.value = false;
    }
  }

  async function getProject() {
    if (projectId.value === null || clientUuid.value === null) return;
    try {
      projectLoading.value = true;
      const response = await api.projects.get(clientUuid.value, projectId.value);
      project.price_type = response.data.price_type;
      project.only_invoice_part_of_year = Boolean(response.data.only_invoice_part_of_year);
      if (project.price_type === 'fixed') {
        project.annual_price = response.data.annual_price;
        project.estimating_price = response.data.estimating_price;
      } else if (project.price_type === 'hourly') {
        project.discount = response.data.discount;
      }
      project.user = response.data.user;
      project.start_date = response.data.start_date;
      project.end_date = response.data.end_date;
    } catch (error) {
      console.error(error);
    } finally {
      projectLoading.value = false;
    }
  }

  // Team members
  const teamMembers = ref<IUserPreviewResource[]>([]);
  const teamMembersLoading = ref(false);

  async function getTeamMembers() {
    try {
      teamMembersLoading.value = true;
      if (clientUuid.value && projectId.value) {
        const response = await api.clients.projects.users(clientUuid.value, projectId.value);
        teamMembers.value = response.data;
      } else {
        const searchParams = new URLSearchParams();
        searchParams.append('without_pagination', '1');
        searchParams.append('status', 'active');
        const response = await api.users.list({ searchParams });
        teamMembers.value = response.data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      teamMembersLoading.value = false;
    }
  }

  // Users
  const userUuid = ref<null | string>(null);
  const originalUserUuid = ref<null | string>(null);
  const originalScheduledTime = ref(0);
  const originalScheduledTimeManual = ref(0);
  const splitEvents = ref<ISplitEventResource[]>([]);
  const users = ref<(IUserListResource | IUserPreviewResource)[]>([]);
  const usersLoading = ref(false);
  const user = computed(() => {
    if (userUuid.value) {
      return users.value.find(({ uuid }) => uuid === userUuid.value) || null;
    }
    return null;
  });

  async function getUsers() {
    try {
      usersLoading.value = true;
      const searchParams = new URLSearchParams();
      searchParams.append('without_pagination', '1');
      searchParams.append('statuses[]', UserStatusType.Active);
      searchParams.append('statuses[]', UserStatusType.Invited);
      const response = await api.users.list({ searchParams });
      users.value = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      usersLoading.value = false;
    }
  }

  // Activity templates
  const activityTemplates = ref<(IActivityTemplateListResource | IActivityTemplateMinimizeResource)[]>([]);
  const activityTemplateLoading = ref(false);

  async function getActivityTemplates() {
    try {
      activityTemplateLoading.value = true;
      const searchParams = new URLSearchParams();
      searchParams.append('without_pagination', '1');
      const response = await api.activityTemplates.list({ searchParams });
      activityTemplates.value = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      activityTemplateLoading.value = false;
    }
  }

  // TimePeriod
  const timePeriodAvailableDatesLoading = ref(false);
  const timePeriodAvailableDates = ref<ITimePeriodAvailableDates | null>(null);

  async function getTimePeriodAvailableDates() {
    try {
      timePeriodAvailableDatesLoading.value = true;
      timePeriodAvailableDates.value = await api.timePeriods.availableDates();
    } catch (error) {
      console.error(error);
    } finally {
      timePeriodAvailableDatesLoading.value = false;
    }
  }

  const frequencies = computed(() => [
    { value: null, label: t('task.frequency.once') },
    { value: 'weekly', label: t('task.frequency.weekly') },
    { value: 'monthly', label: t('task.frequency.monthly') },
    { value: 'quarterly', label: t('task.frequency.quarterly') },
    { value: 'semi-annually', label: t('task.frequency.semi-annually') },
    { value: 'annually', label: t('task.frequency.annually') },
  ]);

  const originalWeekNumber = ref<null | number>(null);

  return {
    // Clients
    clientUuid,
    // Projects
    project,
    projectId,
    projects,
    projectsLoading,
    projectLoading,
    getProjects,
    getProject,
    // Team members
    teamMembers,
    teamMembersLoading,
    getTeamMembers,
    // Users
    userUuid,
    originalUserUuid,
    users,
    usersLoading,
    user,
    getUsers,
    // Activity templates
    activityTemplates,
    activityTemplateLoading,
    getActivityTemplates,
    // Time period
    timePeriodAvailableDates,
    getTimePeriodAvailableDates,
    // Other
    frequencies,
    originalWeekNumber,
    originalScheduledTime,
    originalScheduledTimeManual,
    splitEvents,
    // Work duration
    workingDaysLoading,
    workingDays,
    getUserWorkingDays,
  };
}
