import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { ITimeSheetPreviewResource, ITimeSheetRequest, ITimeSheetResource } from '@/types/TimeSheet';

export default {
  index(options: Options = {}) {
    return httpClient
      .get(route('time-periods.time-sheets.index'), options)
      .json<IResponse<ITimeSheetPreviewResource[]>>();
  },
  get(timeSheet: number, options: Options = {}) {
    return httpClient
      .get(route('time-periods.time-sheets.get', { timeSheet }), options)
      .json<IResponse<ITimeSheetResource>>();
  },
  update(timeSheet: number, json: ITimeSheetRequest, options: Options = {}) {
    return httpClient
      .patch(route('time-periods.time-sheets.update', { timeSheet }), { ...options, json })
      .json<IResponse<ITimeSheetResource> & { next: null | number }>();
  },
};
