<script setup lang="ts">
import { AppButton, FontIcon } from '@/components';
import { useI18n } from 'vue-i18n';

type Props = {
  loading: boolean;
};

const { loading } = defineProps<Props>();

const { t } = useI18n({ useScope: 'global' });
</script>

<template>
  <AppButton
    color="success"
    circle
    light
    size="small"
    :loading="loading"
    v-tooltip.bottom="t('event.report_residual_time')"
  >
    <FontIcon name="clock-check" />
  </AppButton>
</template>
