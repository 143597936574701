<script setup lang="ts">
import { AppButton, FontIcon } from '@/components';
import { useI18n } from 'vue-i18n';

type Props = {
  isPrioritised: boolean;
  loading: boolean;
};

const { isPrioritised, loading } = defineProps<Props>();

const { t } = useI18n({ useScope: 'global' });
</script>

<template>
  <AppButton
    :color="isPrioritised ? 'yellow' : undefined"
    circle
    light
    size="small"
    :loading="loading"
    v-tooltip.bottom="isPrioritised ? t('common.tooltip.deprioritize') : t('common.tooltip.prioritise')"
  >
    <FontIcon v-if="isPrioritised" name="star-filled" />
    <FontIcon v-else name="star" />
  </AppButton>
</template>
