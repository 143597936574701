<script lang="ts" setup>
import { AppBox, AppBoxBody, AppButton, AppLoader, FontIcon, InboxFree, UserTodo } from '@/components';
import { computed, onMounted, reactive, ref } from 'vue';
import { ITodoResource, TodoType } from '@/types/Todo';
import api from '@/services/api';
import { useI18n } from 'vue-i18n';
import useTodos from '@/composables/useTodos';
import { DateTime } from 'luxon';

const filters = reactive({
  withDeadline: false,
});

const { title, userUuid, type } = defineProps<{
  title: string;
  type: 'todos' | 'infos';
  userUuid: string;
}>();

const loading = ref(false);
const todos = ref<ITodoResource[]>([]);
const { isHiddenTodo, getHiddenTodo } = useTodos();
const { t } = useI18n({ useScope: 'global' });

async function getTodos() {
  try {
    todos.value = await api.todos.user[type].all(userUuid);
  } catch (error) {
    console.error(error);
  }
}

const filtered = computed<ITodoResource[]>(() => {
  let localTodos = [...todos.value];

  if (type === 'todos') {
    localTodos = filters.withDeadline ? todos.value.filter((todo) => todo.deadline) : todos.value;
    localTodos = localTodos.filter((todo) => {
      return (
        !isHiddenTodo(todo.id) ||
        (isHiddenTodo(todo.id) && getHiddenTodo(todo.id)?.comments_count !== todo.comments.length) ||
        (isHiddenTodo(todo.id) && todo.deadline && DateTime.fromISO(todo.deadline) <= DateTime.now())
      );
    });
  }

  if (type === 'infos') {
    localTodos = localTodos.filter((todo) => DateTime.fromISO(todo.createdAt) <= DateTime.now());
  }

  return localTodos;
});

const { onCreate, onEdit, onDelete, onReassign } = useTodos({
  onCreated() {
    getTodos();
  },
  onUpdated() {
    getTodos();
  },
  onDeleted(todo) {
    todos.value = todos.value.filter(({ id }) => id !== todo.id);
  },
});

onMounted(async () => {
  loading.value = true;
  await getTodos();
  loading.value = false;
});
</script>

<template>
  <AppBox shadow>
    <AppBoxBody class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0 flex-grow-1">{{ title }}</h3>
      <div class="flex-shrink-0 pl-3">
        <template v-if="type === 'todos'">
          <AppButton
            @click.prevent="filters.withDeadline = !filters.withDeadline"
            class="mr-2"
            v-tooltip.left="
              filters.withDeadline ? t('user.todos.tooltip.show_all') : t('user.todos.tooltip.show_with_deadline')
            "
            light
            circle
          >
            <FontIcon :name="filters.withDeadline ? 'hourglass-off' : 'hourglass-high'" />
          </AppButton>
          <RouterLink
            custom
            :to="{ name: 'todos.index', query: { type: 'messageortodo', recipient: userUuid, status: null } }"
            v-slot="{ href, navigate }"
          >
            <a @click="navigate" :href="href" target="_blank">
              <AppButton v-tooltip.left="t('user.todos.tooltip.show_hidden')" class="mr-2" light circle>
                <FontIcon name="eye" />
              </AppButton>
            </a>
          </RouterLink>
        </template>
        <template v-else-if="type === 'infos'">
          <RouterLink
            custom
            :to="{ name: 'todos.index', query: { type: 'info', recipient: userUuid } }"
            v-slot="{ href, navigate }"
          >
            <a @click="navigate" :href="href" target="_blank">
              <AppButton v-tooltip.left="t('user.todos.tooltip.show_hidden')" class="mr-2" light circle>
                <FontIcon name="eye" />
              </AppButton>
            </a>
          </RouterLink>
        </template>
        <AppButton
          @click.prevent="onCreate({ type: type === 'infos' ? TodoType.Info : TodoType.Todo })"
          v-tooltip.left="t('common.add_new')"
          light
          circle
        >
          <FontIcon name="plus" />
        </AppButton>
      </div>
    </AppBoxBody>
    <AppBoxBody>
      <div v-if="loading" class="text-center">
        <AppLoader size="small" />
      </div>
      <template v-else>
        <InboxFree :icon-width="120" v-if="todos.length === 0" :text="t('common.empty')" />
        <UserTodo
          @edit="onEdit(todo)"
          @delete="onDelete(todo)"
          @updated="getTodos"
          @reassign="onReassign(todo)"
          v-for="todo in filtered"
          :todo="todo"
          :key="todo.id"
          :user-uuid="userUuid"
        />
      </template>
    </AppBoxBody>
  </AppBox>
</template>
