<script lang="ts" setup>
import { computed, ref } from 'vue';

defineOptions({
  name: 'AppButton',
  inheritAttrs: false,
});

const props = defineProps<{
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'yellow';
  size?: 'small' | 'large';
  loading?: boolean;
  circle?: boolean;
  light?: boolean;
  link?: boolean;
  ghost?: boolean;
}>();

const buttonNodeRef = ref<HTMLButtonElement>();

const tag = computed(() => (props.ghost ? 'span' : props.link ? 'a' : 'button'));

const classes = computed(() => [
  'button',
  props.color && { [`is-${props.color}`]: props.color },
  props.size && { [`is-${props.size}`]: props.size },
  { 'is-loading': props.loading },
  { 'is-light': props.light },
  { 'is-circle': props.circle },
]);

function focus() {
  buttonNodeRef.value?.focus();
}

function blur() {
  buttonNodeRef.value?.blur();
}

defineExpose({ focus, blur });
</script>

<template>
  <component ref="buttonNodeRef" :is="tag" :class="classes" v-bind="$attrs">
    <slot />
  </component>
</template>
