<script setup lang="ts">
import { AppButton, FontIcon } from '@/components';
import { useI18n } from 'vue-i18n';

type Props = {
  isDone: boolean;
  loading: boolean;
};

const { isDone, loading } = defineProps<Props>();

const { t } = useI18n({ useScope: 'global' });
</script>

<template>
  <AppButton
    :color="isDone ? undefined : 'success'"
    circle
    light
    size="small"
    :loading="loading"
    v-tooltip.bottom="isDone ? t('common.tooltip.reopen') : t('common.tooltip.complete')"
  >
    <FontIcon v-if="isDone" name="rotate" />
    <FontIcon v-else name="check" />
  </AppButton>
</template>
